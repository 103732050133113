.root {
  > .cmp-container {
    overflow: hidden;
  }
}

.container {

  .cmp-container {
    //overflow: auto;
  }

  &.container--white {

    .cmp-container {
      background-color: $custom-container-background-white;
    }
  }

  &.container--gray {

    .cmp-container {
      background-color: $custom-container-background-gray;
    }

    .cmp-title__text, .cmp-text {
      color: $custom-container-text-gray;
    }

    .text--check-list {
      & ul li {
        &:before {
          background-color: $custom-container-text-gray;
        }
      }
    }
  }

  &.container--gray-secondary {

    .cmp-container {
      background-color: $custom-container-background-gray-secondary;
    }

    .cmp-title__text, .cmp-text {
      color: $custom-container-text-gray-secondary;
    }

    .text--check-list {
      & ul li {
        &:before {
          background-color: $custom-container-text-gray-secondary;
        }
      }
    }
  }

  &.container--quaternary {

    .cmp-container {
      background-color: $custom-container-background-quaternary;
    }

    .cmp-title__text, .cmp-text {
      color: $custom-container-text-quaternary;
    }

    .text--check-list {
      & ul li {
        &:before {
          background-color: $custom-container-text-quaternary;
        }
      }
    }
  }

  &.container--gold {

    .cmp-container {
      background: $custom-container-background-gold-mobile;

      @include from($breakpoint-small) {
        background: $custom-container-background-gold;
      }
    }

    .cmp-title__text, .cmp-text {
      color: $custom-container-text-gold;
    }

    .text--check-list {
      & ul li {
        &:before {
          background-color: $custom-container-text-gold;
        }
      }
    }
  }
}
